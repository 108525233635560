import React, {useState, useEffect, forwardRef} from 'react'
import ConsoleTemplate, { adminUsers } from '../components/consoleTemplate'

import {  Button, IconButton, Fab} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import { getFirestore, collection,  getDocs, query,  orderBy,} from "firebase/firestore";
import { getStorage, ref, getDownloadURL, listAll, getMetadata, deleteObject } from "firebase/storage";
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import firebase from '../components/firebase'

const db = getFirestore();
const storage = getStorage();


const RecordingsView = (props) =>{
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState("info"); // info / success / error
    const [alertMessage, setAlertMessage] = useState("");
    const [folders, setFolders] = useState([]);


    const getEventRecordingsFiles = async () => {
        try {
            
            const storageRef = ref(storage, "live-recordings/");
            const folders = await listAll(storageRef);
            const files = [];
            let deleteCount = 0;
            let folderCount = 0;
            const deleteAfterDays = 467;
            folders.prefixes.forEach( async (folderRef) =>  {
                setIsLoading(true);
                
               // The traverseFolder function is defined inside forEach for a few reasons:
               // 1. Closure: It has access to the outer scope variables like 'files' and 'setFolders'
               // 2. Scoping: It's only needed within this specific forEach loop
               // 3. Isolation: It prevents naming conflicts with other functions
              
               const traverseFolder = async (folderRef, path = '') => {
                
                 const items = await listAll(folderRef);
                 
                 
                 for (const itemRef of items.prefixes) {
                   await traverseFolder(itemRef, `${path}/${itemRef.name}`);
                 }
                 
                 for (const itemRef of items.items) {
                   const metadata = await getMetadata(itemRef);
                   const createdDate = new Date(metadata.timeCreated);
                   const now = new Date();
                   const daysDifference = (now - createdDate) / (1000 * 60 * 60 * 24);
                   
                   const isTooOld = daysDifference > deleteAfterDays;
                   if(isTooOld) {
                        files.push({
                            name: itemRef.name,
                            path: `${path}/${itemRef.name}`,
                            createdDate: metadata.timeCreated,
                            updatedDate: metadata.updated,
                            isTooOld: isTooOld,
                            metadata: metadata,
                        });
                        await deleteObject(itemRef);
                       deleteCount++;
                    }
                 }
                 folderCount++;
               };
               
               // Each folder in 'folders.prefixes' is processed independently
               await traverseFolder(folderRef);
               
               console.log("Files found:", files);
                console.log("files deleted " + files.length + " / " + deleteCount);
                setFolders(files);
                console.log(folderCount + " of " +  folders.prefixes.length + " checked ")
                setIsLoading(false);
               
            });
            
       
        } catch (error) {
            setIsLoading(false);
            console.log("error " + error);
            setAlertType("error");
            setAlertMessage("Error getting event recordings " + error);
            setShowAlert(true);
        }
    }

    const cleanupTSFiles = async () =>{ 
        try {
            const storageRef = ref(storage, "live-recordings/");
            const folders = await listAll(storageRef);
            const files = [];
            folders.prefixes.forEach( async (folderRef) =>  {
            let folderCount = 0;
            let tsFileCount = 0;
            let deleted = 0;
            const traverseFolder = async (folderRef) => {
                folderCount++;
                console.log(`Traversing folder ${folderCount}/${folders.prefixes.length}: ${folderRef.fullPath}`);
                
                const items = await listAll(folderRef);
                for (const itemRef of items.items) {
                    if (itemRef.name.endsWith('.ts')) {
                        tsFileCount++;
                        const metadata = await getMetadata(itemRef);
                        files.push({
                            name: itemRef.name,
                            path: `${folderRef.fullPath}/${itemRef.name}`,
                            createdDate: metadata.timeCreated
                        });
                        
                        await deleteObject(itemRef);
                        deleted++;
                        console.log(`${folderRef.fullPath}/${itemRef.name} Deleted (${deleted} files deleted)`);
                    }
                }
                for (const subFolderRef of items.prefixes) {
                    await traverseFolder(subFolderRef);
                }
            };

            await traverseFolder(folderRef);
            console.log(`Total top-level folders checked: ${folders.prefixes.length}`);
            console.log(`Total .ts files found: ${tsFileCount}`);
            console.log(files);
            
            });
            


        } catch (error){
            setIsLoading(false);
            console.log("error " + error);
            setAlertType("error");
            setAlertMessage("Error getting event recordings " + error);
            setShowAlert(true);
        }
    }


    return (
        <ConsoleTemplate {...props} isLoading={isLoading} tab={3} showAlert={showAlert} setShowAlert={setShowAlert} alertType={alertType} alertMessage={alertMessage}>
            <h2>Recordings</h2>
            <div className="row">
                <Button variant="contained" onClick={getEventRecordingsFiles}>
                    Clean Up Old Recordings
                </Button>&nbsp;
                <Button variant="contained" onClick={cleanupTSFiles}>
                    Clean Up TS Files
                </Button>
            </div>

        </ConsoleTemplate>
    )
};

export default RecordingsView;